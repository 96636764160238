import { useEffect } from "react";
import AOS from "aos";
import { IoMdChatboxes } from "react-icons/io";
import Button from "./utils/Button";
import { Link } from "react-router-dom";
const GetQuote = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="getQuote_section pb-3 pt-sm-0 pt-0">
      <div className="container">
        <div className="getQuote_box">
          <div className="d-flex gap-4">
            <div className="icon">
              <IoMdChatboxes />
            </div>
            <div>
              <div className="title">Free Consultation</div>
              <div className="text">
                We are here to serve you 24/7. Please drop your query here.
              </div>
            </div>
          </div>
          <Link to="/contact-us">
            <Button>Free Consultation</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default GetQuote;
