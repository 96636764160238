import ContactForm from "./organisms/ContactForm";
import GoogleMaps from "./organisms/GoogleMaps";
// import SideItem from "./organisms/SideItem";
import ContactInfo from "./organisms/ContactInfo";
import useScrollTop from "./../../hooks/useScrollTop";
const ContactPage = () => {
  useScrollTop();
  return (
    <section className="contact_section pt-5">
      <div className="container">
        <ContactInfo />
        <div className="row  center pt-5">
          {/* <div className="col-lg-6 col-12">
            <SideItem />
          </div> */}
          <div className="col-lg-12 col-12">
            <ContactForm />
          </div>
        </div>
      </div>
      <GoogleMaps />
    </section>
  );
};

export default ContactPage;
