// @ yup for make custom validation schema
import * as Yup from "yup";

// @ text constant for show text
import text from "../constant/text";

// @ validation schema for contact
export default Yup.object().shape({
  firstName: Yup.string().required(text.FIRST_NAME_REQUIRED),
  lastName: Yup.string().required(text.LAST_NAME_REQUIRED),
  phone: Yup.string()
    .required(text.PHONE_REQUIRED)
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      text.VALID_PHONE
    ),
  email: Yup.string().email(text.EMAIL_REQUIRED).required(text.EMAIL_REQUIRED),
  message: Yup.string().required(text.MESSAGE_REQUIRED),
});
