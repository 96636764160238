import Card from "./../template/Card";

// team constant
import team from "../../../config/constant/team";
const TeamMember = () => {
  return (
    <section className="teamMember_section py-3 pt-lg-4 ">
      <div className="container ">
        <div className="title pb-2">
          Our <span className="secondary_color"> Teams</span>
        </div>
        <div className="row founder_team pt-3 pb-2">
          {team[0].founderTeam?.map((data, index) => {
            return (
              <div className="col-lg-6 col-md-6 col-12">
                <Card
                  image={data.image}
                  name={data.name}
                  position={data.position}
                ></Card>
              </div>
            );
          })}
        </div>
        <div className="row center py-2 pt-md-4 pt-0 small_team">
          {team[1].nepalTeam?.map((data, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-12">
                <Card
                  image={data.image}
                  name={data.name}
                  position={data.position}
                ></Card>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TeamMember;
