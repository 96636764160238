import { FC, useEffect, ReactNode, useContext } from "react";
import { UtilityContext } from "../../contexts/utils";
// react-icons
import { IoClose } from "react-icons/io5";
import AOS from "aos";
interface ModelProps {
  children?: ReactNode;
}

const Model: FC<ModelProps> = ({ children, ...rest }) => {
  const { isOpen, setIsOpen } = useContext(UtilityContext);

  useEffect(() => {
    if (isOpen) {
      // for prevent scroll while model is open
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    AOS.init();
    AOS.refresh();
  }, [isOpen]);
  return (
    <section
      className={`model_section ${isOpen ? "" : "hide"}`}
      data-aos="zoom-in"
      data-aos-duration="500"
    >
      <div className="container">
        <div className="header">
          <div className="close">
            <IoClose onClick={() => setIsOpen(false)} />
          </div>
        </div>
        <div className="contain">{children}</div>
      </div>
    </section>
  );
};

export default Model;
