import { useEffect } from "react";
import AOS from "aos";

import CardBox from "../../../components/utils/CardBox";

// react-bootstrap
import { Form } from "react-bootstrap";

// custom input component
import Input from "../../../components/utils/Input";
// import TextArea from "./../utils/TextArea";
import Button from "../../../components/utils/Button";

//  formik for validation contact schema
import { Formik } from "formik";

// @ import custom Function for todo validation on Change
import OnChangeValidation from "../../../functions/OnChangeValidation";

// custom validation schema
import contactSchema from "../../../config/schema/contactSchema";
import TextArea from "./../../../components/utils/TextArea";
import { IoMailUnreadOutline } from "react-icons/io5";
// email js
import emailjs from "@emailjs/browser";

// for notification
import { toast } from "react-toastify";

const ContactForm = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const { handleActiveValidation, activeValidation } = OnChangeValidation();
  return (
    <section className="contactForm_section pb-5 mb-2  pt-4 mt-md-0 mt-4 ">
      <div className="contactForm_top">
        <CardBox bgColor="#ECE5EE">
          <div className="d-flex gap-3 align-items-center">
            <IoMailUnreadOutline />
            <div className="text_black">
              Write a few words about your query and we’ll answer your query for
              you within <strong>24</strong> hours
            </div>
          </div>
        </CardBox>
      </div>
      <CardBox bgColor="#F0F0F0">
        <div className="row">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              message: "",
            }}
            validateOnChange={activeValidation}
            validateOnBlur={activeValidation}
            onSubmit={async (data, { resetForm }) => {
              // console.log(data);
              // send to email through email js
              try {
                await emailjs.send(
                  "service_rtxr2ij", //service_id
                  "template_nblkbix", //template_id
                  data, //form data
                  "pxmB1iiRfsKQbO0-i" //public key
                );
                toast.success("Your message has been sent successfully");
              } catch (error) {
                toast.error("Something went wrong, please try again");
              }
              // clear form input
              // @ts-ignore
              resetForm({ values: "" });
              // got to top
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            // validate custom validation schema
            validationSchema={contactSchema}
          >
            {({ errors, handleChange, handleSubmit, values }) => {
              return (
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <div className="row pt-2">
                    <div className="col-md-6 col-12 mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Input
                        placeholder="First name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        error={errors.firstName ? "error" : ""}
                      />
                      <small className="input_suggestion_error">
                        {errors.firstName}
                      </small>
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Input
                        placeholder="Last name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        error={errors.lastName ? "error" : ""}
                      />
                      <small className="input_suggestion_error">
                        {errors.lastName}
                      </small>
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Input
                        placeholder="Your email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email ? "error" : ""}
                      />
                      <small className="input_suggestion_error">
                        {errors.email}
                      </small>
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Input
                        placeholder="Phone number"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        error={errors.phone ? "error" : ""}
                      />
                      <small className="input_suggestion_error">
                        {errors.phone}
                      </small>
                    </div>

                    <div className="col-12 mb-3">
                      <TextArea
                        style={{ height: "120px" }}
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        error={errors.message ? "error" : ""}
                      />
                      <small className="input_suggestion_error">
                        {errors.message}
                      </small>
                    </div>
                    <div className="col-12 mb-3">
                      <Button
                        style={{ width: "100%" }}
                        type="submit"
                        onClick={handleActiveValidation}
                      >
                        Send
                      </Button>
                    </div>
                    {/* <div className="col-12 text_black">
                      If you need to have fast responsive. Just contact us at
                      &nbsp;
                      <a href="mailto:info@necanedu.ca">info@necanedu.ca</a>
                    </div> */}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </CardBox>
    </section>
  );
};

export default ContactForm;
