import BreadCrumb from "./../../components/BreadCrumb";
import image from "../../config/constant/image";
import { VscDebugBreakpointLogUnverified } from "react-icons/vsc";
import GetQuote from "../../components/GetQuote";
import useScrollTop from "./../../hooks/useScrollTop";
const ProgramPage = () => {
  useScrollTop();
  const ProgramList = [
    { title: "Engineering" },
    { title: "Science (Physics, Biology, Chemistry,)-	Health" },
    { title: "Business and Finance" },
    { title: "Computer Science and IT" },
    { title: "Agricultural science and forestry" },
    { title: "Mathematics and Statistics" },
    { title: "Media and Journalism" },
    { title: "Arts and Humanity" },
  ];
  const eduLevelList = [
    { title: "Certificate" },
    { title: "Diploma" },
    { title: "Advanced Diploma" },
    { title: "Bachelor Degree" },
    { title: "Master Degree" },
    { title: "PhD" },
  ];
  return (
    <>
      <section className="program_section">
        <BreadCrumb image={image.whoWeAre} title="Program" />
        <div className="container py-5">
          <div className="title">Program</div>
          <div className="text">
            We have numerous programs/courses to choose from, starting from
            college to university. We are flexible in finding appropriate
            institutions based on your study background, society, financial
            stability, and opportunities. The designed methodology will not help
            you to get engaged with educational institutions but also will help
            you to decide your career path while staying here in Canada.
          </div>
          <div className="program_list">
            {ProgramList.map((data, index) => {
              return (
                <div className="item" key={index}>
                  <div className="count">
                    <VscDebugBreakpointLogUnverified />
                  </div>
                  {data.title}
                </div>
              );
            })}
          </div>
          <div className="program_list pt-5">
            <div className="title">Education level</div>
            {eduLevelList.map((data, index) => {
              return (
                <div className="item" key={index}>
                  <div className="count">
                    <VscDebugBreakpointLogUnverified />
                  </div>
                  {data.title}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <GetQuote />
    </>
  );
};

export default ProgramPage;
