import { useEffect } from "react";
import AOS from "aos";
import CardBox from "./../../../components/utils/CardBox";
// react-icons
import { IoMailOutline, IoLocationOutline } from "react-icons/io5";
const ContactInfo = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="contactInfo_section">
      <div className="row">
        {/* <div
          className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-4"
          data-aos="flip-up"
          data-aos-duration="900"
        >
          <CardBox>
            <div className="info_box">
              <div className="icon">
                <IoCallOutline />
              </div>
              <div className="info">
                <div className="title">Contact Number</div>
                <div className="details contact_info_details">
                  <div className="">
                    <a href="tel:+1 (604) 621-0477"> +1 (604) 621-0477</a>
                    &nbsp;
                  </div>
                  <div className="">
                    <a href="tel:+1 (604)825-4393">+1 (604)825-4393</a>
                  </div>
                </div>
              </div>
            </div>
          </CardBox>
        </div> */}
        <div
          className="col-md-6 col-12 mb-lg-0 mb-4"
          data-aos="flip-up"
          data-aos-duration="900"
        >
          <CardBox>
            <div className="info_box">
              <div className="icon">
                <IoMailOutline />
              </div>
              <div className="info">
                <div className="title">Email Address</div>

                <div className="details contact_info_details">
                  <div className="">
                    <a href="mail:info@necanedu.ca"> info@necanedu.ca</a>
                    &nbsp;
                  </div>
                  <div className="">
                    <a href="mailto:necan@necanedu.ca">necan@necanedu.ca</a>
                  </div>
                </div>
              </div>
            </div>
          </CardBox>
        </div>
        <div
          className=" col-md-6 col-12  "
          data-aos="flip-up"
          data-aos-duration="900"
        >
          <CardBox>
            <div className="info_box">
              <div className="icon">
                <IoLocationOutline />
              </div>
              <div className="info">
                <div className="title">Address</div>
                <div className="details">Victoria, B.C, Canada, V8N 2L5 </div>
              </div>
            </div>
          </CardBox>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
