import { createContext, useState } from "react";
export const UtilityContext = createContext<any>(null);
const UtilityProvider = (props: any) => {
  // for store state of model
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UtilityContext.Provider value={{ isOpen, setIsOpen }}>
      {props.children}
    </UtilityContext.Provider>
  );
};
export default UtilityProvider;
