import { useEffect } from "react";
import AOS from "aos";
import image from "../../../config/constant/image";
const WhyChooseUs = () => {
  const text = [
    {
      image: image.university,
      title:
        "Options to choose wide range of universities and college across canada",
    },
    {
      image: image.Counsellors,
      title:
        "Experienced and Internationally trained and Registered Counsellors",
    },
    {
      image: image.document,
      title:
        "Effective and informative guideline for your document preparation and file lodging.",
    },
    {
      image: image.arrangement,
      title: "Accommodation and job hunting services after landing.",
    },
    { image: image.consulting, title: "Career Counselling " },
    { image: image.easySolution, title: "Easy solution for your study needs " },
    { image: image.VisaRate, title: "99% Visa success rate" },
  ];
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="whyChooseUs_section pt-4 pb-3">
      <div className="container">
        <div className="title">
          Why <span className="secondary_color"> choose us</span>
        </div>
        <div className="text pt-2">
          Office located in Canada, directly consulting with universities and
          colleges. Reviewing, analyzing, and preparing documents based on every
          institutional requirement.
        </div>
        <div className="row pt-4 ">
          {text.map((data, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 col-12 mb-5"
                key={index}
                data-aos="flip-up"
                data-aos-duration="900"
              >
                <div className="contain_box">
                  <div className="image">
                    <img src={data.image} alt="we" />
                  </div>
                  <div className="title">{data.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
