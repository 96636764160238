import { Carousel } from "react-bootstrap";
// image
import image from "../../../config/constant/image";
const HomeCarousel = () => {
  const img = [image.carousel1, image.carousel2];
  return (
    <section className="homeCarousel_section">
      <Carousel>
        {img.map((data, index) => {
          return (
            <Carousel.Item interval={2000} key={index}>
              <img className="d-block w-100" src={data} alt="First slide" />
              {/* <Carousel.Caption>
                <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption> */}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
};

export default HomeCarousel;
