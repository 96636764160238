// @ text constant

const text = {
  FIRST_NAME_REQUIRED: "First name is required",
  LAST_NAME_REQUIRED: "Last name is required",
  PHONE_REQUIRED: "Phone number is required",
  EMAIL_REQUIRED: "Email address is required",
  EMAIL_VALID: "Please enter valid email address",
  MESSAGE_REQUIRED: "Message is required",
  VALID_PHONE: "Please enter valid phone number",
};
export default text;
