import { useEffect } from "react";
import AOS from "aos";

const GoogleMaps = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div data-aos="zoom-in" data-aos-duration="900">
      <iframe
        title="google maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1322.212168215749!2d-123.33591769175662!3d48.486754594813235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548f7179103e760f%3A0x16e16ce1f8222273!2sVictoria%2C%20BC%20V8N%202L5%2C%20Canada!5e0!3m2!1sen!2snp!4v1659938426698!5m2!1sen!2snp"
        width="100%"
        height="350"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMaps;
