import Feature from "./organisms/Feature";
import Feature2 from "./organisms/Feature2";
import HomeCarousel from "./organisms/HomeCarousel";
import GetQuote from "./../../components/GetQuote";
import Feature3 from "./organisms/Feature3";

import useScrollTop from "./../../hooks/useScrollTop";
import Goal from "./../about/organisms/Goal";
const HomePage = () => {
  useScrollTop();
  return (
    <section className="homePage_section">
      <HomeCarousel />
      <Feature />
      <Feature2 />
      <Feature3 />
      <Goal />
      <div className="pt-4"></div>
      <GetQuote />
    </section>
  );
};

export default HomePage;
