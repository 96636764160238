import { ReactNode, FC, TextareaHTMLAttributes } from "react";

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  icon?: ReactNode;
  id?: string;
  error?: string;
}

const TextArea: FC<InputProps> = ({ icon, id, error, ...rest }) => {
  return (
    <div className={`input_section ${error}`}>
      <div className="icon">{icon}</div>
      <textarea id={id} {...rest} />
    </div>
  );
};

export default TextArea;
