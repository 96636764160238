import BreadCrumb from "./../../components/BreadCrumb";
import image from "../../config/constant/image";
import ServiceBox from "./organisms/ServiceBox";
import GetQuote from "./../../components/GetQuote";
import useScrollTop from "./../../hooks/useScrollTop";
const ServicePage = () => {
  useScrollTop();
  return (
    <section className="servicePage_section">
      <BreadCrumb image={image.whoWeAre} title="Services" />
      <ServiceBox />
      <GetQuote />
    </section>
  );
};

export default ServicePage;
