import image from "./image";

const team = [
  {
    founderTeam: [
      {
        image: image.unknown,
        name: "Robin Sharma",
        position: "Founder and Principle educational consultant Specialist",
      },
      {
        image: image.raj_team,
        name: "Mr. Raj Sigdel ",
        position: "Co-Founder",
      },
    ],
  },
  {
    nepalTeam: [
      {
        image: image.sita_team,
        name: "Mrs. Sita Adhikari Sapkota  ",
        position: "Senior Consultant",
      },
      {
        image: image.unknown,
        name: "Mr. Prakash Bhusal  ",
      },
    ],
  },
];
export default team;
