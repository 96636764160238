import { useState, useEffect } from "react";
import AOS from "aos";
import { Container, Navbar, Offcanvas, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
const NavBar = () => {
  const location = useLocation().pathname;
  // use -- window dimension hook
  const { width } = useWindowDimensions();

  const [menuOpen, setMenuOpen] = useState(false);
  // state for store active item
  // eslint-disable-next-line
  const [activeItem, setActiveItem] = useState("");

  const handleActive = (e: any) => {
    setActiveItem(e.target.innerText);
  };

  const toggleMenu = () => {
    if (width <= 767) {
      setMenuOpen(!menuOpen);
    }
  };

  const handleClose = () => setMenuOpen(false);

  const [header, setHeader] = useState("navbar_section");
  const listenScrollEvent = (event: any) => {
    if (window.scrollY < 200) {
      return setHeader("navbar_section");
    } else if (window.scrollY > 200) {
      return setHeader("navbar_section2");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    AOS.init();
    AOS.refresh();
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <section className={header}>
      {["md"].map((expand) => (
        <Navbar key={expand} sticky="top" expand={expand}>
          <Container>
            <Navbar.Brand as={Link} to={"/"}>
              NeCan Educational Consultants
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={toggleMenu}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              restoreFocus={false}
              show={menuOpen}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  NeCan Educational Consultants
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link
                    as={Link}
                    to={""}
                    onClick={(e) => {
                      toggleMenu();
                      handleActive(e);
                    }}
                    className={location === "/" ? "active" : ""}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={"about-us"}
                    onClick={(e) => {
                      toggleMenu();
                      handleActive(e);
                    }}
                    className={location === "/about-us" ? "active" : ""}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={"services"}
                    onClick={(e) => {
                      toggleMenu();
                      handleActive(e);
                    }}
                    className={location === "/services" ? "active" : ""}
                  >
                    Services
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={"program"}
                    onClick={(e) => {
                      toggleMenu();
                      handleActive(e);
                    }}
                    className={location === "/program" ? "active" : ""}
                  >
                    Program
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={"contact-us"}
                    onClick={(e) => {
                      toggleMenu();
                      handleActive(e);
                    }}
                    className={location === "/contact-us" ? "active" : ""}
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </section>
  );
};

export default NavBar;
