import { useEffect } from "react";
import AOS from "aos";
import image from "../../../config/constant/image";
const AboutMe = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="aboutMe_section py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="contain-box">
              <div className="title">
                Who <br />
                <span className="secondary_color">We Are?</span>
              </div>
              <div className="heading">
                NeCan Educational consultants (Incorporation number: BC1374585)
              </div>
              <div className="text pt-2">
                We at NeCan Education Consultancy Services, are driven by our
                core values, ethics, expertise and leadership. We believe that
                honesty paves the way for true success. We encourage students to
                use true and genuine information for pursuing their higher
                studies abroad.
                <br />
                <br />
                Student recruitment agency with expert guidance, performance and
                quality information. We are a Canada based educational agency
                that works directly with Canadian universities and colleges
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-4">
            <div className="image_layer">
              <img src={image.whoWeAre} alt="" />
              <div className="overlap">
                <div className="d-flex gap-2 align-items-center">
                  <div className="h1">10</div> Years +
                </div>
                Experiences
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
