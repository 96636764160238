import { useEffect } from "react";
import AOS from "aos";
import CardBox from "./../../../components/utils/CardBox";
import image from "../../../config/constant/image";
const Feature2 = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="feature2_section pb-5 pt-sm-0 pt-5 mt-md-4 mt-0">
      <div className="container">
        <div className="title_box">
          <div className="title">
            We will do, what we are
            <span className="secondary_color"> expert in</span>
          </div>
          <div className="text">
            NeCan Educational Consultants are well-educated, professional and
            experienced in the field of education, and are very successful in
            providing placement to various students present across the globe.
          </div>
        </div>
        <div className="contain_box pt-5">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-0 mb-md-0 mb-4"
              data-aos="zoom-in-up"
              data-aos-duration="500"
            >
              <CardBox>
                <img src={image.CounsellingSpecialist} alt="one" />
                <div className="title d-flex gap-2">
                  <div className="count">1.</div> Counselling Specialist
                </div>
                <div className="text">
                  NeCan has engaged with well known multiple universities and
                  colleges here within Canada. We are successful in providing
                  the insight for every student with accurate guidance and
                  assistance throughout their education session...
                </div>
              </CardBox>
            </div>
            <div
              className="col-lg-4 col-md-6 col-12 mb-md-0 mb-4"
              data-aos="zoom-in-up"
              data-aos-offset="100"
              data-aos-duration="500"
            >
              <CardBox>
                <img src={image.CourseProgramIdentifier} alt="one" />
                <div className="title d-flex gap-2">
                  <div className="count">2.</div> Course/Program Identifier
                </div>
                <div className="text">
                  Finding an appropriate program/course for your higher
                  education is a must. Boredom, failure in your career and
                  degrading your skills are some drawbacks when you are not able
                  to find the right program. Our expertise counsellor will help
                  every student to shortlist the appropriate course that will
                  suit your personal, academic, financial and social
                  environment...
                </div>
              </CardBox>
            </div>
            <div
              className="col-lg-4 col-md-6 col-12 mt-md-5 mt-0"
              data-aos="zoom-in-up"
              data-aos-duration="500"
            >
              <CardBox>
                <img src={image.ApplicationProcess} alt="one" />
                <div className="title d-flex gap-2">
                  <div className="count">3.</div> Application Process
                </div>
                <div className="text">
                  NeCan consultants will identify and provide special attention
                  in preparing all documents required for your application
                  process. Incomplete application / document and / or missing
                  information can lead to your visa failure / rejection...
                </div>
              </CardBox>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature2;
