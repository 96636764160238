type propsType = {
  title: string;
};
const FooterTitle = (props: propsType) => {
  return (
    <section className="footerTitle">
      {props.title}
      <div className="pb-1"></div>
      <div className="progress_bar">
        <div className="color"></div>
      </div>
    </section>
  );
};

export default FooterTitle;
