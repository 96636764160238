import { useEffect } from "react";
import AOS from "aos";
import image from "../../../config/constant/image";
// react-icons
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";
const TopNav = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="top_navSection">
      <div className="container">
        <div className="topNav_box">
          <div className="logo">
            <img src={image.logo} alt="logo" />
            <div className="slogan">
              NeCan Educational consultants <br />
              <span> Incorporation number: BC1374585</span>
            </div>
          </div>
          <div className="contact_info">
            <div className="contact_box">
              <div className="icon">
                <IoMailOutline />
              </div>
              <div className="info">
                <div className="title">email</div>
                <div className="details">info@necanedu.ca</div>
              </div>
            </div>
            <div className="contact_box">
              <div className="icon">
                <IoLocationOutline />
              </div>
              <div className="info">
                <div className="title">Address</div>
                <div className="details">Victoria, B.C, Canada, V8N 2L5</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopNav;
