import { useEffect } from "react";
import AOS from "aos";
type propsType = {
  image: string;
  title: string;
  subTitle?: string;
};
const BreadCrumb = (props: propsType) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="breadCrumb_section">
      <div className="image_overlay">
        <img src={props.image} alt="breadcrumb" />
        <div className="dark_overlay">
          <div className="" data-aos="zoom-in" data-aos-duration="900">
            <div className="title">{props.title}</div>
            <div className="subTitle">{props.subTitle}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
