import { IoMailOutline } from "react-icons/io5";
type propsType = {
  image?: string;
  name?: string;
  position?: string;
  email?: string;
};
const Card = (props: propsType) => {
  return (
    <section className="card_section pb-4">
      <div className="card_box">
        <img src={props.image} alt="team" />
        <div className="details">
          <div className="name">{props.name}</div>
          <div className="position">{props.position}</div>
          {props.email && (
            <div className="email">
              <a href={`mailto:${props.email}`}>
                <IoMailOutline /> {props.email}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Card;
