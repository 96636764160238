import { useEffect } from "react";
import AOS from "aos";
import { VscDebugBreakpointLogUnverified } from "react-icons/vsc";
import { ProgramList, eduLevelList } from "../../../config/constant/program";
const Feature3 = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="feature3_section program_section pb-5  mb-3 pt-5 mt-md-4 mt-0">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="title">
              <span className="secondary_color">Program</span>
            </div>
            <div className="text pt-2">
              We have numerous programs/courses to choose from, starting from
              college to university. We are flexible in finding appropriate
              institutions based on your study background, society, financial
              stability, and opportunities. The designed methodology will not
              help you to get engaged with educational institutions but also
              will help you to decide your career path while staying here in
              Canada.
            </div>
          </div>
          <div className="col-md-8 col-12 mt-md-0 mt-4 ">
            <div className="row contain_box">
              <div className="col-md-6 col-12  pe-md-0 ps-md-5 ps-auto mb-md-0 mb-4">
                <div className="card_box program_list">
                  <div className="title">Education level</div>
                  {eduLevelList.map((data, index) => {
                    return (
                      <div className="item" key={index}>
                        <div className="count">
                          <VscDebugBreakpointLogUnverified />
                        </div>
                        {data.title}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-6 col-12 ps-md-0 ps-auto">
                <div className="secondary_box program_list">
                  <div className="title">Program</div>
                  {ProgramList.map((data, index) => {
                    return (
                      <div className="item" key={index}>
                        <div className="count">
                          <VscDebugBreakpointLogUnverified />
                        </div>
                        {data.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature3;
