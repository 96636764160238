export const ProgramList = [
  { title: "Engineering" },
  { title: "Science (Physics, Biology, Chemistry,)-	Health" },
  { title: "Business and Finance" },
  { title: "Computer Science and IT" },
  { title: "Agricultural science and forestry" },
  { title: "Mathematics and Statistics" },
  { title: "Media and Journalism" },
  { title: "Arts and Humanity" },
];
export const eduLevelList = [
  { title: "Diploma" },
  { title: "Advanced Diploma" },
  { title: "Certificate" },
  { title: "Bachelor Degree" },
  { title: "Master Degree" },
  { title: "PhD" },
];
