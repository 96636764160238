import { useEffect, useContext } from "react";
import AOS from "aos";
import CardBox from "./../../../components/utils/CardBox";
import Button from "./../../../components/utils/Button";
import image from "../../../config/constant/image";
import Model from "../../../components/utils/Model";
import { UtilityContext } from "../../../contexts/utils";
const Feature = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const { setIsOpen } = useContext(UtilityContext);
  return (
    <section className="feature_section py-5">
      <div className="container">
        <div className="row container_box">
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col-md-6 col-12 mt-md-5 mt-0">
                <div className="row">
                  <div className="col-md-12 col-6">
                    <div
                      className="mb-4 p-md-2 p-0"
                      data-aos="flip-up"
                      data-aos-duration="900"
                    >
                      <CardBox>
                        <div className="icon">
                          <img src={image.education} alt="education" />
                        </div>
                        <div className="title">Affordable education</div>
                      </CardBox>
                    </div>
                  </div>
                  <div className="col-md-12 col-6">
                    <div
                      className="mb-4 p-md-2 p-0"
                      data-aos="flip-up"
                      data-aos-duration="900"
                    >
                      <CardBox>
                        <div className="icon">
                          <img src={image.lifestyle} alt="education" />
                        </div>
                        <div className="title">Exciting campus lifestyle </div>
                      </CardBox>
                    </div>
                  </div>
                </div>
                <div
                  className="mb-4 p-md-2 p-0"
                  data-aos="flip-up"
                  data-aos-duration="900"
                >
                  <CardBox>
                    <div className="icon">
                      <img src={image.healthy_lifestyle} alt="education" />
                    </div>
                    <div className="title">Healthy and safe community</div>
                  </CardBox>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div
                  className="mb-4 p-md-2 p-0"
                  data-aos="flip-up"
                  data-aos-duration="900"
                >
                  <CardBox>
                    <div className="icon">
                      <img src={image.immigration} alt="education" />
                    </div>
                    <div className="title">Immigration opportunities</div>
                  </CardBox>
                </div>

                <div
                  className="mb-4 p-md-2 p-0"
                  data-aos="flip-up"
                  data-aos-duration="900"
                >
                  <CardBox>
                    <div className="icon">
                      <img src={image.innovation} alt="education" />
                    </div>
                    <div className="title">
                      Innovative and abundant research opportunities
                    </div>
                  </CardBox>
                </div>
                <div
                  className="mb-4 p-md-2 p-0"
                  data-aos="flip-up"
                  data-aos-duration="900"
                >
                  <CardBox>
                    <div className="icon">
                      <img src={image.opportunity} alt="education" />
                    </div>
                    <div className="title">
                      Opportunities for work and internships while studying
                      abroad for international students
                    </div>
                  </CardBox>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 pt-md-0 pt-4">
            <div
              className="info_section ps-md-3 p-0"
              data-aos="flip-up"
              data-aos-duration="900"
            >
              <div className="title">
                WHY STUDY IN
                <span className="secondary_color"> CANADA? </span>
              </div>
              <div className="text">
                Canada is one of the best places for higher education,
                attracting thousands of students globally each year. Colleges in
                Canada offer world-class education that is recognized around the
                world, and research is considered one of the vital elements of
                any graduate program. The university supports students who
                generate the best research ideas, and grants are provided for
                research activities.
              </div>
              <div
                className="d-flex justify-content-center"
                onClick={() => setIsOpen(true)}
              >
                <Button>How To Start ?</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model>
        <img src={image.howToStart} alt="how to start" />
      </Model>
    </section>
  );
};

export default Feature;
