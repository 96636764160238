import logo from "../../assets/images/icons/logo.png";
// home image
import carousel1 from "../../assets/images/background/img1.jpg";
import carousel2 from "../../assets/images/background/img2.jpg";

// why study in canada
import education from "../../assets/images/icons/education.png";
import lifestyle from "../../assets/images/icons/lifestyle.png";
import healthy_lifestyle from "../../assets/images/icons/healthyLife.png";
import immigration from "../../assets/images/icons/immegration.png";
import innovation from "../../assets/images/icons/idea.png";
import opportunity from "../../assets/images/icons/work.png";

// about me
import whoWeAre from "../../assets/images/background/whoWeAre.jpg";
import university from "../../assets/images/about/university.jpg";
import howToStart from "../../assets/images/about/howToStart.png";
import VisaRate from "../../assets/images/about/99VisaRate.jpg";
import document from "../../assets/images/about/document.jpg";
import Counsellors from "../../assets/images/about/consultant.jpg";
import easySolution from "../../assets/images/about/easySolution.jpg";

// expert
import CounsellingSpecialist from "../../assets/images/expert/CounsellingSpecialist.jpg";
import CourseProgramIdentifier from "../../assets/images/expert/CourseProgramIdentifier.jpg";
import ApplicationProcess from "../../assets/images/expert/ApplicationProcess.jpg";

// service
import internship from "../../assets/images/services/Internship.jpg";
import consulting from "../../assets/images/services/onlineConsulting.jpg";
import arrangement from "../../assets/images/services/TravelArrangementAccomodation.jpg";
import visa from "../../assets/images/services/studentVisa.jpg";
import admission from "../../assets/images/services/GettingAdmission.jpg";

// team
import unknown from "../../assets/images/team/unknown.png";
import raju_team from "../../assets/images/team/Photo_RS.jpg";
import raj_team from "../../assets/images/team/Raj_Sigdel.jpg";
import sita_team from "../../assets/images/team/sita.jpg";

const image = {
  logo: logo,
  carousel1: carousel1,
  carousel2: carousel2,

  howToStart,

  education: education,
  lifestyle: lifestyle,
  healthy_lifestyle: healthy_lifestyle,
  immigration: immigration,
  innovation: innovation,
  opportunity: opportunity,

  whoWeAre: whoWeAre,

  CounsellingSpecialist,
  CourseProgramIdentifier,
  ApplicationProcess,

  visa,
  arrangement,
  consulting,
  internship,
  admission,

  university,
  VisaRate,
  document,
  Counsellors,
  easySolution,

  unknown,
  raju_team,
  raj_team,
  sita_team,
};
export default image;
