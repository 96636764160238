import { useEffect } from "react";
import AOS from "aos";
const Goal = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="goal_section pb-5">
      <div className="container">
        <div className="title">
          Our <span className="secondary_color">Goal</span>
        </div>
        <div className="text pt-4">
          NeCan Consultants are well-educated, professional and experienced in
          the field of education, and are very successful in providing placement
          to various students present across the globe. Starting from course
          selection to final documentation preparation, our guidance will dig
          the pathway for your successful educational career.
        </div>
        <div className="pt-3 d-flex justify-content-end">
          -- Canadian Education consultant (#1906) – Dr. R. Sapkota
        </div>

        <div className="contain_box pt-5">
          <div className="row">
            <div
              className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-4"
              data-aos="flip-up"
              data-aos-duration="900"
            >
              <div className="item_box">
                <div className="d-flex gap-2 align-items-end">
                  <div className="count">01.</div>
                  <div className="title">Program</div>
                </div>
                <div className="text pt-3">
                  Providing assistance with student visa and document
                  preparation.
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-4"
              data-aos="flip-up"
              data-aos-duration="900"
            >
              <div className="item_box">
                <div className="d-flex gap-2 align-items-end">
                  <div className="count">02.</div>
                  <div className="title">Career Advice</div>
                </div>
                <div className="text pt-3">
                  Guiding through a maze of questions to find appropriate
                  solutions that suits your profile.
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-4"
              data-aos="flip-up"
              data-aos-duration="900"
            >
              <div className="item_box">
                <div className="d-flex gap-2 align-items-end">
                  <div className="count">03.</div>
                  <div className="title">Internship program</div>
                </div>
                <div className="text pt-3">
                  Help you to engage in different industries. Finding your right
                  career from where you are now.
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-4"
              data-aos="flip-up"
              data-aos-duration="900"
            >
              <div className="item_box">
                <div className="d-flex gap-2 align-items-end">
                  <div className="count">04.</div>
                  <div className="title">Finance and Scholarship</div>
                </div>
                <div className="text pt-3">
                  Provide information about various international, institutional
                  and national scholarships.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Goal;
