import GetQuote from "../../components/GetQuote";
import AboutMe from "./organisms/AboutMe";
import WhyChooseUs from "./organisms/WhyChooseUs";
import useScrollTop from "./../../hooks/useScrollTop";
import Goal from "./organisms/Goal";
import TeamMember from "./../team/organisms/TeamMember";
const AboutPage = () => {
  useScrollTop();
  return (
    <section className="aboutPage_section">
      <AboutMe />
      <WhyChooseUs />
      <Goal />

      <TeamMember />

      <GetQuote />
    </section>
  );
};

export default AboutPage;
