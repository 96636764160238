import { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";
import FooterTitle from "./../templates/FooterTitle";
// images
import image from "../../../config/constant/image";
// react-icons
import {
  IoLocationOutline,
  IoLogoFacebook,
  IoMailOutline,
} from "react-icons/io5";
const Footer = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="footer_section">
      <div className="container">
        <div className="footer_box">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mb-lg-0 mb-5">
              <FooterTitle title="NeCan Educational consultants" />
              <div className="logo my-2">
                <img src={image.logo} alt="logo" />
              </div>
              <div className="text  ">
                Provide core and hidden information for your successful career.
                A trusted name in Canadian society.
                <br />
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-12 mb-lg-0 mb-5 ps-lg-5 ps-auto">
              <FooterTitle title="Quick links" />
              <div className="footer_items">
                <Link to="">
                  <div className="footer_item">Home</div>
                </Link>
                <Link to="about-us">
                  <div className="footer_item">About Us</div>
                </Link>
                <Link to="services">
                  <div className="footer_item">Services</div>
                </Link>
                <Link to="program">
                  <div className="footer_item">Program</div>
                </Link>
                <Link to="contact-us">
                  <div className="footer_item">Contact Us</div>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-5 footer_contact_us">
              <FooterTitle title="Contact Us" />
              <div className="footer_items">
                <a
                  href="mailto:info@necanedu.ca"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer_item">
                    <div className="icon">
                      <IoMailOutline />
                    </div>
                    info@necanedu.ca
                  </div>
                </a>
                <a
                  href="https://goo.gl/maps/YCRVF9qtF2x4s5No9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer_item">
                    <div className="icon">
                      <IoLocationOutline />
                    </div>
                    Victoria, B.C, Canada, V8N 2L5
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-lg-0 ">
              <FooterTitle title="Working hours" />
              <div className="footer_items">
                <div className="footer_item">Monday 8:00 AM – 6:00 PM</div>

                <div className="footer_item">Tuesday 8:00 AM – 6:00 PM</div>

                <div className="footer_item">Wednesday 8:00 AM – 6:00 PM</div>

                <div className="footer_item">Thursday 8:00 AM – 6:00 PM</div>

                <div className="footer_item">Friday 8:00 AM – 6:00 PM</div>

                <div className="footer_item">Saturday 11:00 AM – 2:00 PM</div>

                <div className="footer_item">Sunday Noon to 4:00 PM </div>
              </div>
            </div>
          </div>
          <div className="footer_social_icon mt-lg-0 mt-5">
            <div className="d-flex social_icon">
              <div className="title">Let’s connect together</div>
              <a
                href="https://www.facebook.com/NeCan-Educational-Consultants-106094592213193/"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoFacebook />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
