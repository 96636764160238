import Footer from "./organisms/Footer";
import NavBar from "./organisms/NavBar";

import { Outlet } from "react-router-dom";
import Copyright from "./organisms/Copyright";
import TopNav from "./organisms/TopNav";
const MainLayout = () => {
  return (
    <div className="mainLayout_section">
      <TopNav />
      <NavBar />
      <Outlet />
      <Footer /> <Copyright />
    </div>
  );
};

export default MainLayout;
