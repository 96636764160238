// add custom sass
import "./assets/sass/main.scss";
// react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// aos styling
import "aos/dist/aos.css";
// react-router dom
import { Routes, Route } from "react-router-dom";
// scss file import
import "react-toastify/dist/ReactToastify.css";
// react-toastify
import { ToastContainer } from "react-toastify";
// @pages
// @main layout
import MainLayout from "./components/mainLayout";
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import ProgramPage from "./pages/program";
import ContactPage from "./pages/contact";
import PageNotFound from "./pages/PageNotFound";
import Scroll from "./components/utils/Scroll";

function App() {
  return (
    <div>
      <Scroll />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="" element={<HomePage />} />
          <Route path="about-us" element={<AboutPage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="program" element={<ProgramPage />} />
          <Route path="contact-us" element={<ContactPage />} />
          {/* for 404 page not found page */}
          <Route path="*" element={<PageNotFound />}></Route>
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}
export default App;
