import { ReactNode, useState, FC, InputHTMLAttributes } from "react";

// react-icons
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: string | undefined;
  icon?: ReactNode;
  id?: string;
  error?: string;
}

const Input: FC<InputProps> = ({ variant, icon, id, error, ...rest }) => {
  const [type, setType] = useState("password");
  //   function to show and hide password
  const handleShowHide = () => {
    type === "text" ? setType("password") : setType("text");
  };
  return (
    <div className={`input_section ${error}`}>
      <div className="icon">{icon}</div>
      {variant === "password" ? (
        <>
          <input id={id} type={type} {...rest} />
          <div className="icon" onClick={handleShowHide}>
            {type === "text" ? <IoEyeOutline /> : <IoEyeOffOutline />}
          </div>
        </>
      ) : (
        <input id={id} {...rest} />
      )}
    </div>
  );
};

export default Input;
