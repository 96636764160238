import { useEffect } from "react";
import AOS from "aos";
import image from "../../../config/constant/image";
import { VscDebugBreakpointLogUnverified } from "react-icons/vsc";
const ServiceBox = () => {
  const text = [
    {
      image: image.visa,
      title: "Student Visa",
      description: [
        { info: "Advice and guidance on the documentation preparation." },
        {
          info: "Providing information about accommodation and career advice. ",
        },
        { info: "Coordinating with the university and visa office. " },
      ],
    },

    {
      image: image.arrangement,
      title: "Travel arrangement and accommodation",
      description: [
        { info: "Travel requirement" },
        {
          info: "Ticket finder",
        },
        { info: "Foreign exchange" },
        { info: "Travel/Medical insurance" },
      ],
    },

    {
      image: image.internship,
      title: "Internship/Co-op program",
      description: [
        { info: "We help you to engage in co-op/internship program" },
        {
          info: "Find the right industries and companies that suit your study background.",
        },
      ],
    },
    {
      image: image.consulting,
      title: " Online consulting and Advice Centre",
      description: [
        { info: "Advice consulting" },
        {
          info: "Prompt",
        },
      ],
    },
    {
      image: image.admission,
      title: "Getting Admission",
      description: [
        { info: "Full assistance with student visa." },
        {
          info: "Lodging and job seeking arrangement.",
        },
        { info: "Course and career advice." },
      ],
    },
  ];
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="serviceBox_section pt-5">
      <div className="container">
        <div className="row">
          {text.map((data, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-12 mb-5" key={index}  >
                <div className="service_box">
                  <img src={data.image} alt="service" />
                  <div className="title">{data.title}</div>
                  <div className="subtitle">
                    <div className="">
                      {data.description.map((data, index) => {
                        return (
                          <div className="item">
                            <div className="">
                              <VscDebugBreakpointLogUnverified />
                            </div>
                            {data.info}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServiceBox;
