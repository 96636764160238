// react-icon
import { MdCopyright } from "react-icons/md";

const Copyright = () => {
  return (
    <section className="copyright_section">
      <div className="container">
        <div className="copyright_box">
          <div className="d-flex align-items-center">
            <MdCopyright style={{ fontSize: "14px" }} />
            &nbsp;copyright 2022. All Right Reserved
          </div>
          <div className=" ">
            Design and Developed By
            <a
              href="https://www.28softwares.com"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;28 Softwares
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Copyright;
