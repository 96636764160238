import React, { useEffect } from "react";
import { BsArrowUpSquareFill } from "react-icons/bs";
const Scroll = () => {
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 350) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  //   handle to go top of screen
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div>
      {scrolled ? (
        <div className="scroll">
          <div onClick={handleGoToTop}>
            <BsArrowUpSquareFill />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Scroll;
